<div class="flex flex-col items-center w-96">
  <h2 mat-dialog-title>{{ 'NOTIFICATION.SEND_TITLE' | translate }}</h2>

  <mat-dialog-content class="w-full">
    <form *ngIf="form; else loader" [formGroup]="form">
      <label class="form-control">
        <div class="label">
          <span class="label-text">{{ 'NOTIFICATION.DESCRIPTION_LABEL' | translate }}</span>
        </div>
        <textarea
          class="textarea textarea-bordered"
          rows="5"
          [ngClass]="{
            'textarea-primary':
              form.get('description')?.valid && form.get('description')?.touched && form.get('description')?.dirty,
            'textarea-accent':
              form.get('description')?.invalid && form.get('description')?.touched && form.get('description')?.dirty
          }"
          [placeholder]="'NOTIFICATION.TYPE_HERE_HINT' | translate"
          formControlName="description"
        ></textarea>
      </label>

      <label class="form-control mt-2">
        <div class="label">
          <span class="label-text">{{ 'NOTIFICATION.RECIPIENT_LABEL' | translate }}</span>
        </div>
        <app-shared-select
          formControlName="recipient"
          [items]="recipients"
          [config]="{
            bindLabel: 'fullName',
            bindValue: 'id',
            appendTo: 'body',
            searchable: true,
          }"
        />
      </label>
    </form>

    <ng-template #loader>
      <div class="p-8 flex justify-center">
        <span class="loading loading-spinner loading-lg text-primary"></span>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="form" align="center" class="flex flex-row justify-center gap-x-2">
    <button class="btn btn-accent" (click)="closePopup()">{{ 'DIALOG.BUTTON.CANCEL' | translate }}</button>
    <button class="btn btn-primary" [disabled]="!form.valid" (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
  </mat-dialog-actions>
</div>
