<div class="flex items-center">
  <label class="relative inline-flex items-center cursor-auto" [ngClass]="{ readonly: disabled }">
    <input
      name="switch-input"
      type="checkbox"
      [checked]="isChecked"
      [disabled]="disabled"
      class="sr-only peer"
      (change)="updateState()"
    />
    <div class="w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:bg-green-700"></div>
    <div
      class="absolute w-5 h-5 bg-white rounded-full border transition-all peer-checked:translate-x-5 peer-checked:border-green-700"
    ></div>
  </label>
</div>
