<div [ngClass]="data.style" class="confirm">
  <h2 mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content class="confirm-content">
    <div [innerHTML]="content"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="confirm-actions flex gap-4 justify-center">
    <button class="btn" (click)="cancel()">
      {{ 'NO' | translate }}
    </button>
    <button class="btn btn-primary" (click)="save()">
      {{ 'YES' | translate }}
    </button>
  </mat-dialog-actions>
</div>
