import { RecipientNotificationDto } from '@agroone/entities'
import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { tap } from 'rxjs'
import { NotificationsService } from '@agroone-app/shared/notifications/services/notifications.service'

@UntilDestroy()
@Component({
  selector: 'app-create-notification-form',
  templateUrl: './create-notification-form.component.html',
})
export class CreateNotificationFormComponent implements OnInit {
  public form: FormGroup
  public recipients: RecipientNotificationDto[] = null

  constructor(
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<CreateNotificationFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { description: string; recipientUserId: number }
  ) {}

  ngOnInit() {
    this.loadUsersList()
  }

  private initForm() {
    this.form = this.formBuilder.group({
      description: [this.data?.description, [Validators.required]],
      recipient: [
        this.recipients?.find((recipient: RecipientNotificationDto) => recipient.id === this.data?.recipientUserId),
        [Validators.required],
      ],
    })
  }

  private loadUsersList() {
    this.notificationsService
      .getNotificationRecipients()
      .pipe(
        untilDestroyed(this),
        tap((v) => {
          this.recipients = v.map((u) => ({ ...u, fullName: `${u.firstName} ${u.lastName}` }))
          this.initForm()
        })
      )
      .subscribe()
  }

  public save(): void {
    this.dialogRef.close({
      description: this.form.value?.description,
      recipient: this.recipients.filter((r) => r.id === this.form.value?.recipient)[0] ?? null,
    })
  }

  public closePopup() {
    this.dialogRef.close(null)
  }
}
